<div *ngIf="showOverlay" class="overlay-banner" [ngClass]="{'fade-in': showOverlay, 'fade-out': !showOverlay}">
    <div class="overlay-content">
        <button class="btn-close" (click)="closeOverlay()"><i class="fas fa-times-circle"></i></button> <!-- Botón de cierre -->
        <h2>Atención afiliado</h2>
        <p>Descargue nuestra aplicación móvil para acceder a sus servicios de manera rápida y sencilla. Para ingresar, utilice su usuario y clave previamente activados.</p>
        <div>
            <button class="btn btn-primary" (click)="redirectToApp()">Descargar</button>
        </div>
    </div>
</div>
<div class="container text-center mt-4" *ngIf="showBanner">
    <mat-card class="info-card">
      <mat-card-content>
        <div class="info-banner">
          <h3 class="banner-title"><i class="fas fa-gifts"></i>  Información importante  <i class="fas fa-glass-cheers"></i></h3>
          
          <p class="banner-message">
            Por razones operativas relacionadas con las fiestas, las autorizaciones que se soliciten a partir de las 13hs del 27/12/2024 se tratarán a partir del 02/01/2025.
          </p>
          <button class="btn-primary" (click)="hideBanner()">Aceptar</button>
        </div>
      </mat-card-content>
    </mat-card>
</div>
<div class="container text-center mt-4">
    <mat-card class="custom-card">
        <mat-card-content>
            <div class="affiliate-attention">
                <p class="mb-2">
                    Si ya ha registrado su usuario, le invitamos a descargar nuestra aplicación móvil.
                </p>
                <div class="d-flex justify-content-center">
                    <img 
                        src="assets/img/google-play-badge.png" 
                        alt="Descarga nuestra app en Google Play" 
                        class="img-fluid img-banner" 
                        style="max-width: 400px; display: block; margin: 0 auto;"
                        (click)="openOverlay()"
                    >
                </div>                                                    
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="container">
    <div class="section-title">
        <!-- <span class="sub-title">Accesos Rapidos</span> -->
        <!-- <h2>Affordable Online Courses and Learning Opportunities​</h2> -->
        <!-- <p>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p> -->
    </div>
    <div class="row">
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="fa fa-id-card"
                       style="color: #c0392b;"></i>
                </div>
                <h3>Mi Credencial</h3>
                <p>El uso de esta credencial es personal e intransferible.</p>
                <a routerLink="/mi-credencial" class="link-btn">Ver...</a>
            </div>
        </div>
        
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="fa fa-calendar"
                       style="color: #c0392b;"></i>
                </div>
                <h3>Turnos</h3>
                <p>Consultorios internos</p>
                <a href="https://signomedico.org/turnos_online" target="_blank" class="link-btn">Ver...</a>
            </div>
        </div>
        
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="far fa-paper-plane"  style="color: #c0392b;"></i>
                </div>
                <h3>Trámites</h3>
                <p>Complete sus solicitudes de autorización y otros procedimientos administrativos.</p>
                <a routerLink="/tramites-disponibles" class="link-btn">Ver...</a>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="far fa-file"  style="color: #c0392b;"></i>
                </div>
                <h3>Mis Solicitudes</h3>
                <p>Controle y realice sus solicitudes.</p>
                <a routerLink="/usuario-solicitudes" class="link-btn">Ver...</a>
            </div>
        </div>


        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="far fa fa-search"  style="color: #c0392b;"></i>
                </div>
                <h3>Cartilla Médica</h3>
                <p>Cartilla Médica, busquedas.</p>
                <a routerLink="/cartilla-buscador" class="link-btn">Ver...</a>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="fa fa-users" style="color: #c0392b;"></i>
                </div>
                <h3>Mis Familiares</h3>
                <p>Familiares a cargo</p>
                <a routerLink="/familiares" class="link-btn">Ver...</a>
            </div>
        </div>
       
    </div>
</div>
